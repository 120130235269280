import { Pane } from "@/components/Pane";
import { useIsMobile } from "@/hooks/use-mobile";
import { useEnrollStore } from "@/providers/enrollStore";
import { ForecastUsage } from "@/views/Plans2/components/ForecastUsage";
import { PlanMap3 } from "../components/PlanDetails/PlanMap/PlanMap3";
import { MarkerMap } from "@/components/Map/MarkerMap";
import { ZipMap } from "@/components/Map/ZipMap";

export const PlansHeader = () => {
  const isMobile = useIsMobile();
  const { service_address_zip, locationSpec, service_coords } =
    useEnrollStore();
  const mapContainerStyle = {
    height: "100%",
    width: "100%",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    position: "relative" as const,
    overflow: "hidden",
  };
  if (!isMobile) {
    return (
      <Pane className="w-full h-full min-h-[40vh]">
        <div className="grid grid-cols-2 w-full h-full gap-8">
          <div className="w-full h-full col-span-1 flex flex-col justify-center items-center">
            <div style={mapContainerStyle}>
              {locationSpec === "zip" && (
                <ZipMap zipCodes={[service_address_zip]} position={service_coords} />
              )}
              {locationSpec === "address" && (
                <MarkerMap
                  markers={[service_coords]}
                  position={service_coords}
                />
              )}
            </div>
          </div>
          <div className="w-full h-full col-span-1 flex flex-col justify-center items-center">
            <ForecastUsage />
          </div>
        </div>
      </Pane>
    );
  }

  return null;
};
