import { Button } from "@/components/ui/button";
import { useMemo } from "react";
import { useSessionStore } from "@/providers/store";
import { usePlansStore } from "@/providers/planStore";
import { PlanInfo } from "@/types/plan";
import { useNavigate } from "react-router-dom";
import { useEnrollStore, EnrollStep } from "@/providers/enrollStore";
export const PlanCardFooter: React.FC<{ plan: PlanInfo }> = ({ plan }) => {
  const navigate = useNavigate();
  const { setModalOpen } = useSessionStore();
  const { setEnroll_step } = useEnrollStore();
  const enrollmentPath =
  location.pathname.split("/").slice(0, -1).join("/") + "/enrollment";
  const gradientStyle = useMemo(() => ({
    background: `linear-gradient(${Math.floor(Math.random() * 360)}deg, #3877ff, #4f9cf9, #504be5)`,
    backgroundSize: "180% 180%",
    animation: `sign-up-gradient-animation ${Math.floor(Math.random() * 3) + 6}s ease infinite`,
  }), []);

  const planStore = usePlansStore();
  const onDetailsOpen = () => {
    setModalOpen("planDetails");
    planStore.setSelectedPlan(plan);
  };
  const onGetStarted = () => {
    setModalOpen(null);
    planStore.setSelectedPlan(plan);
    navigate(enrollmentPath);
    setEnroll_step(EnrollStep.SERVICE_DETAILS);
  };

  return (
    <div className="w-full flex flex-col items-start justify-start gap-4 pointer-events-auto">
      <Button
        className="w-full h-12 text-lg font-medium"
        style={gradientStyle}
        onClick={onGetStarted}
      >
        Check Availability
      </Button>
      <Button variant="ghost" className="w-full h-12" onClick={onDetailsOpen}>
        Learn More
      </Button>
    </div>
  );
};
