import React from "react";
import CallToAction from "./CallToAction";
import Features from "./Features";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import { PopularPlanScroll } from "./PopularPlanScroll";
import PopularPlans from "./PopularPlans";
import ServiceSelector from "./ServiceSelector";
import Testimonials from "./Testimonials";
import ProviderScroll from "./ProviderScroll";
import FloatingGuideButton from "@/components/Guide/FloatingGuideButton";
import ServiceGuide from "@/components/Guide/Guide";
import { useSessionStore } from "@/providers/store";
import Splash from "@/components/Splash";
import { useNavigate } from "react-router-dom";




const HomePage: React.FC = () => {
  const { modalOpen, setModalOpen, splashNavigation } = useSessionStore();
  const navigate = useNavigate();

  if (modalOpen === "splash") {
    return (
      <Splash
        onComplete={() => {
          setModalOpen(null);
          navigate(splashNavigation);
        }}
      />
    );
  }

  return (
    <div className="bg-slate-200 relative">
      <Hero />
      <ServiceSelector onServiceSelect={() => {}} />
      <ProviderScroll />
      <Features />
      <PopularPlans />

      <HowItWorks />
      <Testimonials />
      <CallToAction />
      {/* <FloatingGuideButton />
      {modalOpen === "guide" && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 overflow-y-auto p-4">
          <div className="relative max-w-3xl mx-auto mt-20">
            <ServiceGuide />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default HomePage;