import React, { useState, useMemo } from "react";
import { HelpCircle, Calendar, TrendingUp, AlertTriangle } from "lucide-react";
import { useEnrollStore } from "@/providers/enrollStore";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { cn } from "@/lib/utils";
import { Pane } from "../../../components/Pane";
import { months } from "@/types/time";
import { usePlansStore } from "@/providers/planStore";

export const ForecastUsage: React.FC = () => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { getAveragePricePlan, getLowestPricePlan } = usePlansStore();
  const { locationSpec } = useEnrollStore();
  const onEditUsage = () => {
    console.log("Edit Usage");
  };

  const currentUsage = 1000;

  // Base factors for each month relative to average (1.0)
  const monthlyFactors = {
    Jan: 0.75,
    Feb: 0.7,
    Mar: 0.7,
    Apr: 0.7,
    May: 1.0,
    Jun: 1.4,
    Jul: 1.6,
    Aug: 1.8,
    Sep: 1.4,
    Oct: 0.85,
    Nov: 0.75,
    Dec: 0.85,
  };

  // Calculate actual usage data based on current average usage
  // const data = useMemo(() => {
  //   return Object.entries(monthlyFactors).map(([month, factor]) => ({
  //     month: `${month}`,
  //     usage: Math.round(currentUsage * factor),
  //     label: month,
  //     factor,
  //   }));
  // }, [currentUsage]);

  const lowestPriceData = useMemo(() => {
    return Object.entries(monthlyFactors).map(([month, factor]) => ({
      month: `${month}`,
      usage: getLowestPricePlan() * factor * currentUsage,
      label: month,
      factor,
    }));
  }, []);

  const averagePriceData = useMemo(() => {
    return Object.entries(monthlyFactors).map(([month, factor]) => ({
      month: `${month}`,
      usage: getAveragePricePlan() / 100 * factor * currentUsage,
      label: month,
      factor,
    }));
  }, []);

  const data = useMemo(() => {
    return Object.entries(monthlyFactors).map(([month, factor]) => ({
      month: `${month}`,
      averageCost: getAveragePricePlan() / 100 * factor * currentUsage,
      lowestCost: getLowestPricePlan() / 100 * factor * currentUsage,
      label: month,
      factor,
    }));
  }, [getAveragePricePlan, getLowestPricePlan, currentUsage]);

  // const peakMonth = useMemo(() => {
  //   return data.reduce(
  //     (max, item) => (item.usage > max.usage ? item : max),
  //     data[0]
  //   );
  // }, [data]);

  // const peakIncrease = useMemo(() => {
  //   return (((peakMonth.usage - currentUsage) / currentUsage) * 100).toFixed(0);
  // }, [peakMonth, currentUsage]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-100">
          <p className="font-medium text-gray-900 mb-1">{data.label}</p>
          <div className="flex items-baseline gap-1">
            <span className="text-lg font-semibold text-blue-600">
              {payload[0].value.toFixed(0).toLocaleString()}
            </span>
            <span className="text-sm text-gray-500">kWh</span>
          </div>
          <span className="text-sm text-gray-500">Estimated</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-full p-2 justify-start" style={{ height: "100%" }}>
      <div className="w-full space-y-4">
        {/* Header */}
        <div className="flex items-center gap-2">
          <div className="p-1.5 bg-blue-50 rounded-lg">
            <TrendingUp className="w-4 h-4 text-blue-600" />
          </div>
          <h2 className="text-base font-bold text-gray-900">Cost Forecast</h2>
        </div>

        {/* Chart */}
        <div className="w-full h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis
                dataKey="month"
                tick={{ fontSize: 12, fill: "#6B7280" }}
                tickLine={false}
                axisLine={false}
              />

              <YAxis
                tick={{ fontSize: 12, fill: "#6B7280" }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `$${value.toFixed(2)}`}
              >
              </YAxis>
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "#F3F4F6" }}
              />
              <Bar
                dataKey="averageCost"
                fill="#92C5FD"
                radius={[4, 4, 0, 0]}
                maxBarSize={10}
              />
              <Bar
                dataKey="lowestCost"
                fill="#6EE7B7"
                radius={[4, 4, 0, 0]}
                maxBarSize={10}
              />
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      </div>
  );
};

export default ForecastUsage;
